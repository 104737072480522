<template>
  <v-app-bar id="app-bar" absolute app color="transparent" flat height="75">
    <v-btn class="mr-3" elevation="1" fab small @click="$vuetify.breakpoint.smAndDown ? setDrawer(!drawer) : $emit('input', !value)">
      <v-icon v-if="value">mdi-view-quilt</v-icon>

      <v-icon v-else>mdi-dots-vertical</v-icon>
    </v-btn>

    <v-toolbar-title class="hidden-sm-and-down font-weight-light" v-text="title"/>

    <v-spacer />

    <v-progress-circular v-if="loading" indeterminate color="primary"/>

    <v-menu bottom left offset-y origin="top right" transition="scale-transition" open-on-hover>
      <template #activator="{ attrs, on }">
        <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
          <v-icon>far fa-list-check</v-icon>
        </v-btn>
      </template>

      <v-list flat>
        <v-list-item v-for="t in displayTypes" :key="t.value">
          <v-checkbox :input-value="isVisible(t.value)" @change="(active) => changeVisible(t.value, active)" :label="t.text" @click.stop />
        </v-list-item>
      </v-list>
    </v-menu>

    <v-btn class="ml-2" min-width="0" text href="/#/">
      <v-icon>mdi-view-dashboard</v-icon>
    </v-btn>

    <v-btn v-if="!profile._id" class="ml-2" min-width="0" text to="/login">
      <v-icon>far fa-user</v-icon>
    </v-btn>
    <v-menu v-else bottom left min-width="200" offset-y origin="top right" transition="scale-transition" open-on-hover>
      <template #activator="{ attrs, on }">
        <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
          <v-icon>fas fa-user</v-icon>
        </v-btn>
      </template>

      <v-list :tile="false" flat nav>
        <v-hover v-slot="{ hover }">
          <v-list-item to="/profile" :class="{red: hover}">
            Profil
          </v-list-item>
        </v-hover>
        <v-divider class="mb-2 mt-2" />
        <v-hover v-slot="{ hover }">
          <v-list-item :class="{red: hover}" @click="logout">
            Logout
          </v-list-item>
        </v-hover>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import { useGraphQL } from '@/plugins/graphql'
import { displayTypes } from '@/main'

export default {
  name: 'DashboardCoreAppBar',

  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  computed: {
    ...mapState(['drawer']),
    ...mapGetters(['profile', 'loading', 'visibletypes', 'isVisible']),
    title () {
      if (this.$route.name) {
        return this.$route.name
      } else {
        return this.$store.getters.getTitle
      }
    },
    displayTypes () {
      return displayTypes.map(t => ({
        ...t,
        text: t.text.replace(/ \(v[^)]*\)/, '')
      }))
    }
  },

  methods: {
    ...mapMutations({
      setDrawer: 'SET_DRAWER'
    }),
    logout () {
      this.login('LOGOUT', 'LOGOUT')
    },
    changeVisible (type, active) {
      if (active && !this.visibletypes.find(t => t === type)) {
        this.$store.commit('SET_VISIBLE', [...this.visibletypes, type])
      } else {
        this.$store.commit('SET_VISIBLE', this.visibletypes.filter(t => t !== type))
      }
    }
  }
}
</script>
